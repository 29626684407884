import * as Sentry from "@sentry/sveltekit"
import { PUBLIC_ENVIRONMENT, PUBLIC_DEBUG_CSP } from "$env/static/public"

let sampleRate = {
  development: 0.0,
  staging: 1.0,
  production: 0.1,
}[PUBLIC_ENVIRONMENT]

Sentry.init({
  dsn: "https://66f2a34a4a7d2fd67e677db7f72166b4@o282971.ingest.sentry.io/4506235020312576",
  tracesSampleRate: 1.0,
  environment: PUBLIC_ENVIRONMENT,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: sampleRate,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [Sentry.replayIntegration()],

  // Callback to modify/block error and messages
  beforeSend(event) {
    // Don't send anything in development and if debug variable is not explicitly set to 1
    if (PUBLIC_ENVIRONMENT === "development" && PUBLIC_DEBUG_CSP !== "1")
      return null

    return event
  },
})

/**
 * @param {Object} params
 * @param {any} params.error
 * @param {any} params.event
 */
const myErrorHandler = ({ error, event }) => {
  console.error("An error occurred on the client side:", error, event)
}

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler)
