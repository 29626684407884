import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83')
];

export const server_loads = [0,2,3,6,4,8];

export const dictionary = {
		"/(app)/(home)": [~20,[2]],
		"/(app)/(compilation)/360/[responseId]/add-provider": [~9,[3]],
		"/admin/dashboard": [~46,[6],[7]],
		"/admin/hashtags": [~47,[6],[7]],
		"/admin/hashtags/new-hashtag": [~49,[6],[7]],
		"/admin/hashtags/[tagId]": [~48,[6],[7]],
		"/admin/lives": [~50,[6],[7]],
		"/admin/lives/new-live": [~54,[6],[7]],
		"/admin/lives/[liveId]": [~51,[6],[7]],
		"/admin/lives/[liveId]/new-poll": [~53,[6],[7]],
		"/admin/lives/[liveId]/[pollId]": [~52,[6],[7]],
		"/admin/maspi": [~55,[6],[7]],
		"/admin/maspi/compare": [~56,[6],[7]],
		"/admin/maspi/reports": [~57,[6],[7]],
		"/admin/maspi/roles": [~58,[6],[7]],
		"/admin/maspi/roles/[roleId]": [~59,[6],[7]],
		"/admin/mentoring": [~60,[6],[7]],
		"/admin/mentoring/[trackId]": [~61,[6],[7]],
		"/admin/mentoring/[trackId]/edit": [~63,[6],[7]],
		"/admin/mentoring/[trackId]/new-instance": [~64,[6],[7]],
		"/admin/mentoring/[trackId]/[instanceId]": [~62,[6],[7]],
		"/admin/news": [~65,[6],[7]],
		"/admin/news/new-news": [~67,[6],[7]],
		"/admin/news/[newsId]": [~66,[6],[7]],
		"/admin/questionnaires": [~68,[6],[7]],
		"/admin/questionnaires/(survey-types)/survey-reports": [~69,[6],[7]],
		"/admin/questionnaires/(survey-types)/survey-reports/[surveyId]": [~70,[6],[7]],
		"/admin/questionnaires/(survey-types)/surveys": [~71,[6],[7]],
		"/admin/questionnaires/(survey-types)/surveys/new-survey": [~73,[6],[7]],
		"/admin/questionnaires/(survey-types)/surveys/[surveyId]": [~72,[6],[7]],
		"/admin/questionnaires/templates": [~74,[6],[7]],
		"/admin/questionnaires/templates/[questionnaireId]": [~75,[6],[7]],
		"/admin/settings": [76,[6],[7]],
		"/admin/settings/organization-profile": [~77,[6],[7]],
		"/admin/users": [~78,[6],[7]],
		"/admin/users/[accountId]": [~79,[6],[7]],
		"/admin/users/[accountId]/manage-roles": [~80,[6],[7]],
		"/(app)/(feedback-actions)/ask-feedback": [16,[2,4]],
		"/(app)/(feedback-actions)/create-exchange": [17,[2,4]],
		"/errors": [~81],
		"/(app)/game": [~21,[2]],
		"/(app)/(feedback-actions)/give-feedback": [18,[2,4]],
		"/(app)/(feedback-actions)/give-feedback/[feedbackOriginId]": [~19],
		"/(auth)/join/[inviteId]": [~40,[5]],
		"/(app)/live/[liveId]": [~22,[2]],
		"/maintenance": [82],
		"/(app)/news/[newsId]": [~23,[2]],
		"/onboarding/user": [~83,[8]],
		"/(auth)/org-missing": [~41,[5]],
		"/(auth)/password-reset": [42,[5]],
		"/(app)/profile-info/[accountId]": [~24,[2]],
		"/(app)/(compilation)/questionnaire/[responseId]": [~10,[3]],
		"/(app)/(compilation)/questionnaire/[responseId]/submit": [~11,[3]],
		"/(app)/report": [~25,[2]],
		"/(app)/report/feedback/[exchangeId]": [~26,[2]],
		"/(app)/report/maspi/[responseId]": [~27,[2]],
		"/(app)/report/mentoring/[instanceId]": [~28,[2]],
		"/(app)/report/survey/[questionnaireId]/[responseId]": [~29,[2]],
		"/(app)/report/threesixty/[questionnaireId]/[responseId]": [~30,[2]],
		"/(app)/report/threesixty/[questionnaireId]/[responseId]/manage": [~31,[2]],
		"/(app)/settings": [32,[2]],
		"/(app)/settings/account": [~33,[2]],
		"/(app)/settings/privacy-policy": [~34,[2]],
		"/(app)/settings/privacy-policy/en": [35,[2]],
		"/(app)/settings/privacy-policy/it": [36,[2]],
		"/(app)/settings/profile": [~37,[2]],
		"/(auth)/signin": [~43,[5]],
		"/(auth)/signout": [~44,[5]],
		"/(auth)/signup": [~45,[5]],
		"/(app)/start-360": [~38,[2]],
		"/(app)/(compilation)/survey-completed": [14,[3]],
		"/(app)/(compilation)/surveys-provider/[receiverResponseId]": [~15,[3]],
		"/(app)/(compilation)/survey/[userRecipientId]": [~12,[3]],
		"/(app)/(compilation)/survey/[userRecipientId]/endcard": [~13,[3]],
		"/(app)/switch-organization": [~39,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';